@import '../../assets/scss/variables';

.faq-tabs{
    padding: 0rem 0rem;
    position: relative;
    .p-tabview{
        .p-tabview-nav-container{
            .p-tabview-nav-content{
                .p-tabview-nav{
                    display: inline-flex;
                    border-radius: 0px;
                    width: 100%;
                    background: $quinary;
                    border: none;
                    margin: 0 auto;
                    .p-unselectable-text{
                        .p-tabview-nav-link{
                            padding: 1.7rem 4rem;
                            background: transparent;
                            color: $black;
                            border-radius: 0px;
                            border: none;
                            .p-tabview-title{
                                font-weight: 500;
                                font-size: 18px;
                            }
                            &:focus{
                                box-shadow: none;
                            }
                            &:hover{
                                background: $black;
                                color: $white;
                            }
                        }
                        &.p-highlight{
                            .p-tabview-nav-link{
                                background: $teritary;
                                color: $white;
                            }
                        }
                    }
                    .p-tabview-ink-bar{
                        display: none;
                    }
                }
            }
        }
        .p-tabview-panels{
            background: transparent;
            padding: 2rem 0px;
            h2{
                text-align: left;
                margin-bottom: 2rem;
                font-size: 28px;
            }
            .p-accordion{
                padding: 0px;
                margin: 0px;
            }
        }
    }
    .search-block{
        display: flex;
        justify-content: end;
        margin-bottom: 1rem;
        .pi-search{
            margin-left: 5px;
        }
        .p-inputtext{
            border: 2px solid rgba(35, 37, 39, 0.24);
            border-radius: 0px;
            width: 100%;
            height: 45px;
            min-width: 265px;
        }
        .search-button{
            position: absolute;
            right: 4px;
            border-radius: 0px;
            padding: 11px 30px;
            top: 3px;
            background: $secondary;
            border: none;
            .p-button-label{
                font-size: 14px;
                font-weight: 400;
            }
            &:hover{
                background: $primary;
            }
        }
        .p-input-icon-left{
            position: absolute;
            display: inline-block;
            top: 28px;
            z-index: 1;
            right: 10px;
            .p-inputtext{
                background: $white;
                border-radius: 6px;
            }
        }
    }
}
.form {
    .error-message{
        color: $error-msg;
        font-size: 13px;
        margin: 5px 2px;
    }
}
.faq-banner-section{
    .aboutus-header-section{
        min-height: auto;
    }
    .custom-container{
        padding: 7rem 0rem 0rem !important;
        &::after{
            display: none;
        }
        &::before{
            display: none;
        }
    }
}

@media (max-width:960px){
    .faq-tabs{
        .p-tabview{
            .p-tabview-nav-container{
                .p-tabview-nav-content{
                    .p-tabview-nav{
                        display: block;
                        .p-unselectable-text{
                            .p-tabview-nav-link{
                                padding: 1.5rem 2rem !important;
                                &::after{
                                    display: none;
                                }
                                .p-tabview-title{
                                    font-size: 16px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .search-block{
            position: unset;
            text-align: right;
            margin-bottom: 1rem;
            .p-input-icon-left{
                position: relative;
                top: 0px;
                right: 0px;
            }
        }
    }
}
@media (min-width:768px) and (max-width:960px){
    .faq-tabs{
        .p-tabview{
            .p-tabview-nav-container{
                .p-tabview-nav-content{
                    .p-tabview-nav{
                        display: inline-flex;
                        .p-unselectable-text{
                            .p-tabview-nav-link{
                                padding: 1.5rem 2rem;
                            }
                        }
                    }
                }
            }
        }
        .search-block{
            position: unset;
            text-align: right;
            margin-bottom: 1rem;
        }
        &.device-tabs{
            .p-tabview{
                .p-tabview-nav-container{
                    .p-tabview-nav-content{
                        .p-tabview-nav{
                            display: block;
                            .p-unselectable-text{
                                .p-tabview-nav-link{
                                    padding: 1rem 2rem;
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
}
@media (min-width:961px) and (max-width:1199px){
    .faq-tabs{
        &.device-tabs{
            .p-tabview{
                .p-tabview-nav-container{
                    .p-tabview-nav-content{
                        .p-tabview-nav{
                            display: inline-flex;
                            .p-unselectable-text{
                                .p-tabview-nav-link{
                                    padding: 1.4rem 1.2rem;
                                    .p-tabview-title{
                                        font-size: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }
        .search-block{
            position: unset;
            text-align: right;
            margin-bottom: 1rem;
        }
    }
}