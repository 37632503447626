@import '../../assets/scss/variables';

// **** CSS **** //
.page-not-found{
    background: url(../../assets/images/page-not-found-bg.jpg) no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    .go-to-home{
        padding: 24px 40px;
        box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.15);
        background: $btnprimary;
        border: none;
        margin-top: 2rem;
        border-radius: 0px;
        color: $white;
        display: inline-block;
        margin:2rem auto 0px;
        font-weight: 400;
        font-size: 18px;
        .pi-chevron-left{
            font-size: 14px;
            position: relative;
            top: 1px;
            margin-right: 3px;
        }
        &:hover{
            background: $white;
            color: $black;
        }
    }
    .header-text{
        .header-text-box{
            h1{
                font-size: 64px !important;
                span{
                    font-size: 40px !important;
                }
            }
        }
    }
}