// GeneralSans Font

@font-face {
  font-family: 'GeneralSans';
  src: url('../scss/fonts/GeneralSans-Regular.woff2') format('woff2'),
       url('../scss/fonts/GeneralSans-Regular.woff') format('woff'),
       url('../scss/fonts/GeneralSans-Regular.ttf') format('truetype'),
       url('../scss/fonts/GeneralSans-Regular.eot') format('eot');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('../scss/fonts/GeneralSans-Medium.woff2') format('woff2'),
       url('../scss/fonts/GeneralSans-Medium.woff') format('woff'),
       url('../scss/fonts/GeneralSans-Medium.ttf') format('truetype'),
       url('../scss/fonts/GeneralSans-Medium.eot') format('eot');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('../scss/fonts/GeneralSans-Bold.woff2') format('woff2'),
       url('../scss/fonts/GeneralSans-Bold.woff') format('woff'),
       url('../scss/fonts/GeneralSans-Bold.ttf') format('truetype'),
       url('../scss/fonts/GeneralSans-Bold.eot') format('eot');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

// RocGrotesk Font

@font-face {
  font-family: 'RocGrotesk';
  src: url('../scss/fonts/RocGrotesk-Regular.woff2') format('woff2'),
       url('../scss/fonts/RocGrotesk-Regular.woff') format('woff'),
       url('../scss/fonts/RocGrotesk-Regular.ttf') format('truetype'),
       url('../scss/fonts/RocGrotesk-Regular.eot') format('eot');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'RocGrotesk';
  src: url('../scss/fonts/RocGrotesk-Medium.woff2') format('woff2'),
       url('../scss/fonts/RocGrotesk-Medium.woff') format('woff'),
       url('../scss/fonts/RocGrotesk-Medium.ttf') format('truetype'),
       url('../scss/fonts/RocGrotesk-Medium.eot') format('eot');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'RocGrotesk';
  src: url('../scss/fonts/RocGrotesk-Bold.woff2') format('woff2'),
       url('../scss/fonts/RocGrotesk-Bold.woff') format('woff'),
       url('../scss/fonts/RocGrotesk-Bold.ttf') format('truetype'),
       url('../scss/fonts/RocGrotesk-Bold.eot') format('eot');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
