@import '../../assets/scss/variables';

// **** Contact Page CSS **** //
@mixin button { 
    padding: 24px 40px;
    box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.30);
    background: $btnprimary;
    border: none;
    margin-top: 1rem;
    border-radius: 0px;
    // width: 100%;
    // max-width: 265px;
    .p-button-label{
        font-size: 18px;
        font-family: $font-base;
        font-weight: 600;
    }
    &:hover{
        background: $btnprimaryover;
    }
  } 

.inner-header-section{
    padding: 40px 165px;
    min-height: 750px;
    background: $primary;
    .header-text{
        color: $white;
        margin-top: 6rem;
        h1{
            font-size: 48px;
            font-weight: 700;
            margin: 0px;
        }
        p{
            font-size: 20px;
            font-family: $font-secondary;
            font-weight: 400;
            line-height: 150%; 
            margin: 25px auto;
            width: 100%;
            max-width: 100%;
        }
        .contact-heading-block{
            width: 90%;
            max-width: 630px;
            margin: 0 auto;
            padding: 5rem 0rem;
            p{
                line-height: 150%; 
                max-width: 100%;
            }
            h1{
                padding: 0px 8rem;
                line-height: 110%;
            }
        }
    }
    &.contact-header{
        position: relative;
        &::before{
            content: '';
            width: 517px;
            height: 672px;
            position: absolute;
            background: url('../../assets//images/contact-bg-left.png') no-repeat;
            left: 0rem;
            bottom: -16rem;
        }
        &::after{
            content: '';
            width: 502px;
            height: 659px;
            position: absolute;
            background: url('../../assets//images/contact-bg-right.png') no-repeat;
            right: 0px;
            top: 10rem;
        }
    }
}
.contact-form-section{
    padding: 40px 165px;
    .form-box{
        border-radius: 0px;
        background: $white;
        box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.03);
        min-height: 450px;
        border: 1px solid $input-border;
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        margin-top: -20rem;
        .contact_form{
            .p-component{
                border-radius: 0px;
                border: 1px solid $input-border;
                height: 50px;
                font-size: 16px;
                font-weight: 400;
                &.p-inputtextarea{
                    height: 135px;
                }
                &::placeholder {
                    color: $input-border;
                    font-weight: 400;
                  }
                  
                &::-ms-input-placeholder { 
                    color: $input-border;
                    font-weight: 400;
                  }
            }
            p{
                margin: 0px;
                color: $black;
                font-size: 16px;
                font-family: $font-secondary;
            }
            .contact-button{
                @include button;
                .p-button-label{
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .custom-phoneno-field{
                .react-tel-input{
                    font-family: $font-secondary;
                    .form-control {
                        border-radius: 0px;
                        border: 1px solid $input-border;
                        height: 50px;
                        font-size: 16px;
                        font-weight: 400;
                        width: 100%;
                        font-family: $font-secondary;
                    }
                    .flag-dropdown{
                        border-radius: 0px;
                        .selected-flag{
                            border-radius: 0px;
                        }
                    }
                }
            }
        }
    }
    .contact-box{
        padding: 2rem 3rem;
        text-align: center;
        h3{
            font-size: 24px;
            font-weight: 700;
        }
        p{
            color: $black;
            font-size: 16px;
            font-family: $font-secondary;
            line-height: 25px;
            &.whatsapp-tag{
                width: 100%;
                max-width: 245px;
                margin: 1rem auto;
            }
        }
        a{
            font-size: 16px;
            color: $teritary;
            font-weight: 700;
            font-family: $font-secondary;
            display: inline-block;
            border-bottom: 1px solid $white;
            &:hover{
                color: $secondary;
                border-bottom: 1px solid $secondary;
            }
            &.faq-link{
                display: table;
                margin: 0px auto;
                position: relative;
                top: 10px;
            }
        }
        img{
            width: 80px;
        }
    }
    .error-message{
        color: $error-msg;
        font-size: 13px;
        margin: 5px 2px;
    }
}

@media (max-width:961px){
    .inner-header-section.contact-header{
        &::before{
            width: 210px;
            height: 285px;
            bottom: -7rem;
            background-size: 100%;
        }
        &::after{
            width: 210px;
            height: 285px;
            top: 20rem;
            background-size: 100%;
        }
    }
    .inner-header-section .header-text .contact-heading-block h1{
        padding: 0px 0rem;
    }
}

@media (min-width:961px) and (max-width:1199px){
    .inner-header-section.contact-header{
        &::before{
            width: 210px;
            height: 285px;
            bottom: 6rem;
            background-size: 100%;
        }
        &::after{
            width: 210px;
            height: 285px;
            top: 15rem;
            background-size: 100%;
        }
    }
    .inner-header-section .header-text .contact-heading-block h1{
        padding: 0px 0rem;
    }
}

@media (min-width:1200px) and (max-width:1480px){
    .inner-header-section.contact-header{
        &::before{
            width: 300px;
            height: 350px;
            bottom: 4rem;
            background-size: 100%;
        }
        &::after{
            width: 300px;
            height: 350px;
            top: 12rem;
            background-size: 100%;
        }
    }
    .inner-header-section .header-text .contact-heading-block h1{
        padding: 0px 0rem;
    }
}

