@import '../../assets/scss/variables';

.affiliate-header-section {
    padding-bottom: 16rem;
    position: relative;
    .header-text {
        .header-text-box{
            margin-top: 115px;
            width: 100%;
            max-width: 760px;
            margin: 10rem auto 0px;
            padding: 0px;
            h1{
                padding-right: 0rem;
            }
            p{
                padding-right: 0rem;
            }
        }
        .go-to-home{
            padding: 24px 50px;
            box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.15);
            background: $btnprimary;
            border: none;
            margin-top: 2rem;
            border-radius: 0px;
            color: $white;
            display: inline-block;
            margin:2rem auto 0px;
            font-weight: 400;
            font-size: 18px;
            position: relative;
            .pi-chevron-left{
                font-size: 14px;
                position: relative;
                top: 1px;
                margin-right: 3px;
            }
            &:hover{
                background: $btnprimaryover;
            }
        }
    }
    &::before{
        content: '';
        width: 517px;
        height: 672px;
        position: absolute;
        background: url('../../assets//images/contact-bg-left.png') no-repeat;
        left: 0rem;
        top: 12.8rem;
    }
    &::after{
        content: '';
        width: 502px;
        height: 659px;
        position: absolute;
        background: url('../../assets//images/contact-bg-right.png') no-repeat;
        right: 0px;
        top: 10.7rem;
    }
}
.affiliate-program-section {
    .choose-plans-button {
        padding: 24px 40px;
        box-shadow: 0px 8px 20px 0px rgba(89, 51, 138, 0.15);
        background: $teritary;
        border: none;
        margin-top: 2rem;
        border-radius: 0px;
        color: $white;
        display: inline-block;
        margin: 2rem 0px;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        &:hover{
            background: $white !important;
        }
    }
}
.affiliate-options{
    .option-box{
        margin: 15px 20px 20px 20px;
        padding: 50px 20px;
        p{
            font-size: 17px;
        }
        .p-image{
            img{
                height: 70px;
            }
        }
        &.small-content-box{
            p{
                width: 100%;
                max-width: 310px;
                margin: 0 auto;
            }
        }
    }
    .p-carousel-container{
        .p-link{
            width: 50px;
            height: 50px;
            border: 1px solid $secondary;
            background: $secondary;
            .p-icon{
                width: 18px;
                height: 18px;
                color: $white;
            }
            &:hover{
                background: $primary;
                border: 1px solid $primary;
                .p-icon{
                    color: $white;
                }
            }
            &.p-disabled{
                background: $quaternary;
                opacity: 0.75;
                .p-icon{
                    color: $secondary;
                }
            }
        }
        .p-carousel-items-content{
            margin-top: 0rem;
        }
    }
}
.affiliate-program-section{
    padding: 0rem 165px;
    .affiliate-program-image{
        line-height: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        img{
            width: 90%;
            max-width: 778px;
        }
    }
}
.steps-section{
    padding: 5rem 0px 1rem;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    .card{
        padding: 4rem 0rem;
        background: url(../../assets/images/map-affiliate.png) no-repeat center;
        background-size: contain;
    }
    h2{
        margin: 0px;
    }
    .relative{
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        .steps-box{
            position: relative;
            width: 100%;
            max-width: 400px;
            height: 400px;
            display: flex;
            justify-content: center;
            background: $white;
            z-index: 1;
            margin:0 auto;
            border-radius: 100%;
            box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.05);
            h3{
                font-size: 22px;
                font-weight: 500;
                color: $secondary;
                text-align: center;
                width: 100%;
                max-width: 70%;
                line-height: 32px;
                margin-top: 8rem;
                font-family: $font-secondary;
                p{
                    font-size: 75px;
                    font-weight: 600;
                    margin: 0rem 0rem 2rem;
                    font-family: $font-base;
                    &.secondary-color{
                        color: $teritary;
                    }
                }
                &.step-one{
                    width: 100%;
                    max-width: 55%;
                }
            }
            .p-image{
                display: block;
            }
            .steps-icon{
                width: 85px;
                height: 85px;
                display: flex;
                border-radius: 50%;
                background: $secondary;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: -10px;
                top: 70px;
                &.secondary-color{
                    background: $teritary;
                }
                .p-image{
                    img{
                        width: 100%;
                        max-width: 40px;
                    }
                }
            }
        }
        .stepsnumber{
            position: absolute;
            color: $white;
            right: 70px;
            top: 68px;
            z-index: 0;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            &::after{
                content: '';
                position: absolute;
                top: 30px;
                right: -205px;
                width:200px;
                height:200px;
                background-color : $black;
                -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 35%, 50% 70%, 0 35%, 0 0);
                clip-path: polygon(50% 0%, 100% 0, 100% 35%, 50% 70%, 0 35%, 0 0);
                transform: rotate(-90deg);
            }
            p{
                display: block;
                position: relative;
                left: 32px;
                z-index: 9;
                font-size: 45px;
                text-align: center;
                line-height: 30px;
                span{
                    font-size: 18px;
                    display: block;
                    text-transform: uppercase;
                }
            }
            &.secondary-color{
                &::after{
                    background-color : $teritary;
                }
            }
        }
    }
    .steps-description-p-tag{
        font-family: $font-secondary;
        font-size: 18px;
    }
}
.sales-info-section{
    padding: 2rem 0px 6rem;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    .info-box{
        border-radius: 0px;
        background: $secondary;
        padding: 50px;
        .div-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            padding-bottom: 15px;
            padding-top:45px;
            ul{
                margin: 0px;
                padding: 0px;
                li{
                    list-style: none;
                    color: $white;
                    margin-right: 1rem;
                    h3{
                        margin: 0px;
                        font-size: 30px;
                        font-weight: 500;
                    }
                    p{
                        margin: 0px;
                        font-size: 18px;
                        font-weight: 300;
                    }
                }
            }
            .right{
                text-align: right;
                color: $white;
                p{
                    margin: 0px;
                    font-size:20px;
                    font-weight: 500;
                    span{
                        font-size:18px;
                        font-weight: 300;
                        display: block;
                        margin: 2px 0px;
                    }
                }
            }
            &.first-row{
                padding-top: 0px;
            }
        }
        .info_p{
            font-size: 16px;
            font-style: italic;
            font-weight: 300;
            color: $white;
            margin: 50px 0px 0px;
            margin-left: auto;
            width: 50%;
            text-align: right;
        }
    }
}
.getting-started-section{
    .getting-started-box{
        text-align: left;
        padding: 45px;
        h2{
            text-align: left;
        }
        p{
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            margin: 0px 0px 40px;
            line-height: 36px;
        }
        ul{
            margin: 0px;
            padding: 0px;
            display: flex;
            li{
                list-style: none;
                margin-right: 25px;
                h3{
                    margin: 0px 0px 2px;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                }
                p{
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
                span{
                    &.number{
                        width: 65px;
                        height: 65px;
                        background: $teritary;
                        border-radius: 0px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 35px;
                        color: $white;
                        margin-top: 5px;
                    }
                }
                .view-all-button{
                    display: inline-block;
                    margin: 0px;
                    background: $btnprimary;
                    &:hover{
                        background: $btnprimaryover;
                    }
                }
            }
        }
    }
    .getting-started-right-box{
        border-radius: 0px;
        background-image: url(../../assets/images/get-started-image.png);
        min-height: 950px;
        position: relative;
        left: -5rem;
        display: flex;
        align-items: center;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        &::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: $secondary;
            opacity: 0.5;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            border-radius: 0px;
        }
        .info{
            position: relative;
            z-index: 1;
            padding: 45px 5rem;
            left: 4rem;
            width: 90%;
            h2{
                text-align: left;
                color: $white;
                line-height: 110%;
                margin-bottom: 50px;
            }
            a{
                margin: 20px 0px 0px 0px;
                display: block;
                max-width: 100%;
                background: $btnprimary;
                &.btn-white{
                    background: $white;
                    color: $secondary;
                    &:hover{
                        background: $btnprimary;
                        color: $white;
                    }
                }
                &:hover{
                    background: $btnprimaryover;
                }
            }
        }
    }
}

// Responsive CSS

@media (min-width:1200px) and (max-width:1460px){
    .affiliate-program-section, .sales-info-section{
        padding: 1rem 40px 2rem;
    }
    .steps-section{
        padding: 2rem 40px 0.5rem;
        .stepsnumber{
            right: 70px;
        }
    }
    .getting-started-section{
        padding: 2rem 40px;
        .getting-started-right-box{
            .info{
                padding: 40px;
                left: 4rem;
                h2{
                    font-size: 40px;
                    line-height: 55px;
                    width: 90%;
                    margin-bottom: 35px;
                }
                a{
                    display: block;
                }
            }
        }
    }
}

@media (max-width:768px){
    .affiliate-header-section{
        background-position: bottom;
        padding-bottom: 0rem;
        .header-text{
            .go-to-home{
                padding: 10px 40px;
                font-size: 16px;
            }
        }
        &::before{
            display: none;
        }
        &::after{
            display: none;
        }
    }
    .affiliate-options{
        top: 0rem;
        .option-box {
            margin: 15px 0px 20px 0px;
            padding: 25px 10px;
        }
    }
    .affiliate-program-section,.sales-info-section {
        padding: 1rem 20px 2rem;
    }
    .getting-started-section{
        .getting-started-box{
            padding: 20px;
            ul li{
                margin-right: 15px;
                span.number{
                    width: 45px;
                    height: 45px;
                    font-size: 25px;
                }
            }
        }
        .getting-started-right-box{
            left: 0rem;
            min-height: auto;
            margin-bottom: 2rem;
            .info{
                left: 0rem;
                padding: 30px;
                h2{
                    font-size: 30px;
                    margin-bottom: 25px;
                }
                a{
                    display: block;
                    font-size: 16px;
                    padding: 10px 20px;
                }
            }
        }
    }
    .sales-info-section .info-box{
        padding: 25px;
        .div-row{
            display: block;
            padding-top: 25px;
            ul{
                li{
                    h3{
                        font-size: 20px;
                    }
                }
            }
            .right{
                text-align: left;
                margin-top: 15px;
            }
        }
        .info_p{
            margin: 25px 0px 0px;
            width: 100%;
            text-align: left;
        }
    }
    .steps-section{
        padding: 25px 20px 10px;
        .relative{
            .stepsnumber{
                top: 45px;
                &::after{
                    display: none;
                }
            }
            .steps-box{
                max-width: 340px;
                height: 340px;
                h3{
                    font-size: 17px;
                    line-height: 28px;
                    margin-top: 6rem;
                }
            }
        }
    }
}
@media (min-width:769px) and (max-width:960px){
    .affiliate-header-section{
        padding-bottom: 0rem;
        .header-text{
            .header-text-box{
                max-width: 495px;
                .go-to-home{
                    padding: 10px 40px;
                    font-size: 16px;
                }
            }
        }
    }
    .affiliate-options .option-box{
        margin: 15px 10px 20px 10px;
        padding: 50px 10px;
    }
    .affiliate-program-section, .sales-info-section {
        padding: 2rem 20px 2rem;
    }
    .getting-started-section{
        margin-bottom: 2rem;
        .getting-started-box{
            padding: 20px;
            ul li{
                margin-right: 15px;
                span.number{
                    width: 45px;
                    height: 45px;
                    font-size: 25px;
                }
            }
        }
        .getting-started-right-box{
            left: -1rem;
            .info{
                left: 2rem;
                padding: 24px 2rem;
                h2{
                    line-height: 42px;
                    font-size: 30px;
                    margin-bottom: 25px;
                }
                a{
                    display: block;
                    padding: 10px 20px;
                }
            }
        }
    }
    .steps-section .relative{
        .stepsnumber{
            height: 195px;
            right: 55px;
            top: 102px;
            &::after{
                display: none;
            }
        }
        .steps-box{ 
            max-width: 240px;
            height: 240px;
            h3{
                font-size: 15px;
                line-height: 20px;
                margin-top:2 .5rem;
            p{
                font-size: 45px;
                margin: 2rem 0rem 1rem;
            }
            }
            .steps-icon{
                left: 2px;
                top: 18px;
                width: 70px;
                height: 70px;
            }
        }
    }
    .sales-info-section .info-box .div-row ul li h3{
        font-size: 24px;
    }
    .affiliate-header-section .header-text .header-text-box{
        margin: 5rem auto;
    }
    .steps-section {
        padding:3rem 40px;
    }
    .affiliate-header-section{
        padding-bottom: 0rem;
        &::before{
            background-size: 100%;
            width: 160px;
        }
        &::after{
            background-size: 100%;
            width: 160px;
        }
    }
}
@media (min-width:961px) and (max-width:1199px){
    .affiliate-program-section, .sales-info-section{
        padding: 2rem 20px 2rem;
    }
    .getting-started-section{
        padding-bottom: 1.5rem;
        .getting-started-box{
            padding: 25px;
            p{
                font-size: 18px;
            }
        }
        .getting-started-right-box{
            left: -2rem;
            .info{
                padding: 30px;
                left: 2.5rem;
                h2{
                    font-size: 32px;
                }
                a{
                    display: block;
                }
            }
        }
    }
    .steps-section .relative .steps-box h3{
        font-size: 18px;
        line-height: 26px;
        p{
            font-size: 56px;
        }
    }
    .offer-section .option-box{
        padding-left: 2rem;
    }
    .affiliate-header-section .header-text .header-text-box {
        margin: 5rem auto;
    }
    .steps-section {
        padding: 3rem 40px;
    }
    .steps-section .relative{
        .stepsnumber{
            height: 195px;
            right: 55px;
            top: 102px;
            &::after{
                display: none;
            }
        }
        .steps-box{ 
            max-width: 265px;
            height: 265px;
            h3{
                font-size: 15px;
                line-height: 20px;
                margin-top:3.5rem;
            p{
                font-size: 45px;
                margin: 2rem 0rem 1rem;
            }
            }
            .steps-icon{
                left: 2px;
                top: 18px;
                width: 70px;
                height: 70px;
            }
        }
    }
    .affiliate-header-section{
        padding-bottom: 0rem;
        .header-text .header-text-box{
            max-width: 495px;
        }
        &::before{
            background-size: 100%;
            width: 250px;
        }
        &::after{
            background-size: 100%;
            width: 250px;
        }
    }
}
@media (min-width:1200px) and (max-width:1460px){
    .steps-section .relative .steps-box h3{
        font-size: 20px;
        line-height: 28px;
    }
    .affiliate-header-section{
        padding-bottom: 0rem;
        .header-text .header-text-box{
            margin: 6rem auto;
            max-width: 660px;
        }
        &::before{
            background-size: 100%;
            width: 300px;
        }
        &::after{
            background-size: 100%;
            width: 300px;
        }
    }
}
@media (max-width:768px){
    .affiliate-header-section .header-text .header-text-box{
        margin-top: 50px;
        margin-bottom: 3rem;
    }
}
@media (min-width:1200px) and (max-width:1780px){
    .steps-section .relative{
        .steps-box{
            width: 330px;
            height: 330px;
            h3{
                font-size: 20px;
                line-height: 30px;
                margin-top: 6rem;
            }
        }
        .stepsnumber{
            &:after{
                top: 0px;
                right: -150px;
            }
        }
    }
}
@media (min-width:1461px) and (max-width:1750px){
    .affiliate-header-section .header-text .header-text-box{
        margin: 8rem auto;
    }
    .affiliate-header-section{
        padding-bottom: 10rem;
        &::before{
            background-size: 100%;
            width: 400px;
        }
        &::after{
            background-size: 100%;
            width: 400px;
        }
    }
}